export const endpoints = {
    account: {
        checkEmailExists: 'api/v1/account/auth/check-email-exists',
        editUserData: 'api/v1/account/user',
        login: 'api/v1/account/auth/login',
        getFullUserData: 'api/v1/account/user',
        createInstance: 'api/v1/instance',
        checkInstanceNameIfExists: 'api/v1/instance/instance-name-validator',
        editInstance: 'api/v1/instance',
        deleteInstanceById: 'api/v1/instance/:id',
        createServer: 'api/v1/server',
        editServer: 'api/v1/server',
        deleteServerById: 'api/v1/server/:id',
        getServerByName: 'api/v1/server/name/:serverName',
        sendLoginUrl: 'api/v1/account/auth/send-login-url',
        crypt: 'api/v1/account/auth/crypt',
        getInstanceByOrganizationId: 'api/v1/account/instance/:organizationId',
        moveInstanceFilesToAnother: 'api/v1/instance/move-instance-files-to-another',
        changeInstancePlan: 'api/v1/instance/change-instance-plan',
    },
    stripe: {
        getUserStripeCard: 'api/v1/stripe/card',
        submitNewStripeCard: 'api/v1/stripe/card',
    }

}