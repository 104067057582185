import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { endpoints } from '../constants/endpoints';
import { SpHttpCommonService } from './sp-http-common.service';
import { UserI } from '../interfaces/user.interface';
import { TotalumServerI } from '../interfaces/server.interface';
import { PlansI, TotalumInstanceI } from '../interfaces/instance.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiSdkService {

  constructor(private spHttpCommonService:SpHttpCommonService) { }


  public async checkEmailExists(email: string) {
    const url = this.getUrl(endpoints.account.checkEmailExists);
    return await this.spHttpCommonService.httpPost(url, {email: email});
  }

  public async editUserData(user:UserI) {
    const url = this.getUrl(endpoints.account.editUserData);
    return await this.spHttpCommonService.httpPut(url, user);
  }

  public async login(email: string, secret: string) {
    const url = this.getUrl(endpoints.account.login);
    return await this.spHttpCommonService.httpPost(url, {email: email, secret: secret});
  }

  public async getFullUserData(noRedirect?: boolean) {
    const url = this.getUrl(endpoints.account.getFullUserData);
    return await this.spHttpCommonService.httpGet(url, null as any, noRedirect);
  }

  public async getUserStripeCard() {
    const url = this.getUrl(endpoints.stripe.getUserStripeCard);
    return await this.spHttpCommonService.httpGet(url, null as any);
  }

  public async submitNewStripeCard(cardToken: string) {
    const url = this.getUrl(endpoints.stripe.submitNewStripeCard);
    return await this.spHttpCommonService.httpPost(url, {cardToken: cardToken});
  }

  public async getInstanceByOrganizationId(organizationId: string) {
    const url = this.getUrl(endpoints.account.getInstanceByOrganizationId, {organizationId: organizationId});
    return await this.spHttpCommonService.httpGet(url, null as any);
  }

  public async checkInstanceNameIfExists(name: string, recaptchaToken:string) {
    const url = this.getUrl(endpoints.account.checkInstanceNameIfExists);
    return await this.spHttpCommonService.httpPost(url, {instanceName: name, recaptchaToken: recaptchaToken});
  }

  public async createInstance(instance: TotalumInstanceI, recaptchaToken:string, serverName?:string) {
    const url = this.getUrl(endpoints.account.createInstance);
    return await this.spHttpCommonService.httpPost(url, {instance, recaptchaToken, serverName});
  }

  public async editInstance(instance: TotalumInstanceI, recaptchaToken:string, serverName?:string) {
    const url = this.getUrl(endpoints.account.editInstance);
    return await this.spHttpCommonService.httpPut(url, {instance, recaptchaToken, serverName});
  }

  public async deleteInstanceById(instanceId: string) {
    const url = this.getUrl(endpoints.account.deleteInstanceById, {id: instanceId});
    return await this.spHttpCommonService.httpDelete(url,);
  }

  public async createServer(server: {name:string,description:string}, recaptchaToken:string) {
    const url = this.getUrl(endpoints.account.createServer);
    return await this.spHttpCommonService.httpPost(url, {server, recaptchaToken});
  }

  public async editServer(server: TotalumServerI, recaptchaToken:string) {
    const url = this.getUrl(endpoints.account.editServer);
    return await this.spHttpCommonService.httpPut(url, {server, recaptchaToken});
  }

  public async getServerByName(serverName: string) {
    const url = this.getUrl(endpoints.account.getServerByName, {serverName: serverName});
    return await this.spHttpCommonService.httpGet(url, null as any);
  }

  public async deleteServerById(serverId: string) {
    const url = this.getUrl(endpoints.account.deleteServerById, {id: serverId});
    return await this.spHttpCommonService.httpDelete(url,);
  }

  public async sendLoginUrl(email: string, recaptchaToken:string) {
    const url = this.getUrl(endpoints.account.sendLoginUrl);
    return await this.spHttpCommonService.httpPost(url, {email: email, recaptchaToken: recaptchaToken});
  }

  public async crypt(data: string) {
    const url = this.getUrl(endpoints.account.crypt);
    return await this.spHttpCommonService.httpPost(url, {data: data});
  }

  public async moveInstanceFilesToAnother(organizationId:string, organizationIdToMoveFilesTo: string, recaptchaToken:string) {
    const url = this.getUrl(endpoints.account.moveInstanceFilesToAnother);
    return await this.spHttpCommonService.httpPost(url, {organizationId: organizationId, organizationIdToMoveFilesTo: organizationIdToMoveFilesTo,recaptchaToken: recaptchaToken});
  }

  public async changeInstancePlan(organizationId:string, newPlan: PlansI, recaptchaToken:string) {
    const url = this.getUrl(endpoints.account.changeInstancePlan);
    return await this.spHttpCommonService.httpPost(url, {organizationId: organizationId, newPlan: newPlan,recaptchaToken: recaptchaToken});
  }

  private getUrl(url:string, params?:any){
    if(params){
      Object.keys(params).forEach(key => {
        url = url.replace(`:${key}`, params[key]);
      });
    }
    return environment.apiDomain + url;
  }


}
