<ion-content>
    <div class="modal-header-menu">
        <div class="modal-header-menu-backbutton" (click)="onGoBack()">
            <ion-icon name="arrow-back-outline"></ion-icon>
            Volver
        </div>
    </div>
    <br><br>
    <div style="display: flex;align-items: center;">
        <img style="width: 80px;height: 80px;margin-left: 5px;" src="../../../assets/Totalum_Symbol.png">
        <div style="font-size: 20px;padding: 5%;padding-left: 0%;color: rgb(61, 61, 61);">
            <span *ngIf="!edit"> Crear nuevo</span>
            <span *ngIf="edit">Editar</span>
            proyecto
        </div>
    </div>

    <div style="padding:5%;margin-top: -40px;">

        <form [formGroup]="instanceForm">

            <ion-list>

                <div>
                    <div>
                        <ion-item lines="full" class="form-inputs-margin">
                            <ion-input type="text" [disabled]="edit"
                                placeholder="Introduce el identificador del proyecto (ej: mi-proyecto)"
                                [clearInput]="true" label="Nombre del proyecto (no se podrá cambiar)"
                                label-placement="stacked" formControlName="organizationId"
                                (ionInput)="toLowercase($event)"></ion-input>
                        </ion-item>
                        <div class="form-input-error" color="danger"
                            *ngIf="instanceForm.get('organizationId')?.invalid && instanceForm.get('organizationId')?.touched">
                            <ng-container *ngIf="!instanceForm.get('organizationId')?.valid">
                                La proyectoId tiene que tener entre 4 y 35 caracteres y no se puede empezar con un
                                número. El único carácter especial habilitado es "-".
                                <br>Ejemplos: miproyecto, mi-proyecto, mi-proyecto-2, mi-proyecto-2-3
                            </ng-container>
                        </div>
                    </div>
                    <ion-item lines="full" class="form-inputs-margin">
                        <ion-label position="stacked">Plan</ion-label>
                        <ion-select formControlName="plan" placeholder="Selecciona un plan"
                            (ionChange)="calculePrice()">
                            <ion-select-option *ngFor="let plan of plansAvailable"
                                [value]="plan">{{plan}}</ion-select-option>
                        </ion-select>
                    </ion-item>
                    <div class="form-input-error" color="danger"
                        *ngIf="instanceForm.get('plan')?.invalid && instanceForm.get('plan')?.touched">
                        <ng-container *ngIf="!instanceForm.get('plan')?.valid">Es obligatorio seleccionar un plan
                            válido.</ng-container>
                    </div>
                </div>
                <div>
                    <ion-item lines="full" class="form-inputs-margin">
                        <ion-input type="text" placeholder="Introduce la descripción del proyecto" [clearInput]="true"
                            label="Descripción" label-placement="stacked" formControlName="description"></ion-input>
                    </ion-item>
                    <div class="form-input-error" color="danger"
                        *ngIf="instanceForm.get('description')?.invalid && instanceForm.get('description')?.touched">
                        <ng-container *ngIf="!instanceForm.get('description')?.valid">Es obligatorio introducir una
                            descripción válida de máximo 200 caracteres.</ng-container>
                    </div>
                </div>
            </ion-list>
        </form>


        <div *ngIf="edit">
            <br>
            <div>Mover archivos de este proyecto a otro</div>
            <ion-item lines="full" class="form-inputs-margin">
                <ion-label position="stacked">Seleccionar proyecto a donde mover los archivos</ion-label>
                <ion-select placeholder="Selecciona un proyecto" [(ngModel)]="organizationIdSelectedForMoveFiles">
                    <ion-select-option *ngFor="let instance of instancesAvailableForMoveFiles"
                        [value]="instance.organizationId">
                        {{ instance.organizationId }}
                    </ion-select-option>
                </ion-select>
            </ion-item>
            <br>
            <ion-button [disabled]="isLoading" (click)="moveFilesToAnotherInstance(organizationIdSelectedForMoveFiles)" *ngIf="organizationIdSelectedForMoveFiles">Mover archivos a
                {{organizationIdSelectedForMoveFiles}}</ion-button>

        </div>

        <br>
        <div *ngIf="priceToPay && priceToPay > 0 && (!edit || showUpdatePlan)"
            style="font-size: 18px;padding: 5px;border: solid 1px rgb(221, 221, 221);border-radius: 5px;background-color: rgb(247, 247, 247);">
            Al 
            <span *ngIf="!showUpdatePlan">crear el </span>
            <span *ngIf="showUpdatePlan">Actualizar el plan del </span>
            proyecto se te cobrará <b>{{priceToPay}}€ + IVA mensuales</b> recurrentes a la tarjeta que
            tienes vinculada a tu cuenta.
        </div>

        <ion-spinner *ngIf="isLoading" name="lines"></ion-spinner>

        <ion-button *ngIf="!edit" [disabled]="isLoading" mode="ios" class="button-margin-top" (click)="onSubmit()">
            <ion-label>Crear Proyecto</ion-label>
        </ion-button>

        <ion-button *ngIf="edit" [disabled]="isLoading" mode="ios" class="button-margin-top" (click)="onEdit()">
            <ion-label>Editar Proyecto</ion-label>
        </ion-button>

        <ion-button *ngIf="showUpdatePlan" [disabled]="isLoading" mode="ios" class="button-margin-top" (click)="updatePlan()">
            <ion-label>Update Plan</ion-label>
        </ion-button>

        <br><br><br>

        <div class="progress-bar" *ngIf="isLoading">
            <div class="progress"></div>
        </div>

    </div>

</ion-content>